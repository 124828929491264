import * as React from "react";

const SVGAdvice = (props) => (
	<svg
		width={66}
		height={66}
		viewBox="0 0 66 66"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect width={66} height={66} fill="#00A8E4" />
		<path
			d="M41.75 29.2498C41.7506 27.7434 41.3622 26.2624 40.6225 24.9502C39.8829 23.6379 38.817 22.5388 37.528 21.7593C36.239 20.9797 34.7706 20.5462 33.2649 20.5006C31.7592 20.455 30.2673 20.7989 28.9335 21.499C27.5996 22.199 26.4692 23.2316 25.6514 24.4967C24.8337 25.7618 24.3565 27.2166 24.2658 28.7203C24.1752 30.2239 24.4743 31.7255 25.1342 33.0796C25.7941 34.4338 26.7924 35.5946 28.0325 36.4498L24.25 42.9998L27.07 43.1161L28.58 45.4998L32.9125 37.9948C32.9425 37.9948 32.97 37.9998 33 37.9998C33.03 37.9998 33.0575 37.9961 33.0875 37.9948L37.42 45.4998L38.96 43.1673L41.75 42.9998L37.9675 36.4498C39.1345 35.6463 40.0886 34.5711 40.7476 33.3167C41.4065 32.0624 41.7505 30.6667 41.75 29.2498ZM26.75 29.2498C26.75 28.0137 27.1166 26.8053 27.8033 25.7775C28.4901 24.7497 29.4662 23.9486 30.6082 23.4756C31.7503 23.0025 33.0069 22.8787 34.2193 23.1199C35.4317 23.3611 36.5453 23.9563 37.4194 24.8304C38.2935 25.7045 38.8888 26.8181 39.1299 28.0305C39.3711 29.2429 39.2473 30.4995 38.7742 31.6416C38.3012 32.7836 37.5001 33.7597 36.4723 34.4465C35.4445 35.1333 34.2361 35.4998 33 35.4998C31.3424 35.4998 29.7527 34.8413 28.5806 33.6692C27.4085 32.4971 26.75 30.9074 26.75 29.2498Z"
			fill="white"
		/>
		<path
			d="M33 33C35.0711 33 36.75 31.3211 36.75 29.25C36.75 27.1789 35.0711 25.5 33 25.5C30.9289 25.5 29.25 27.1789 29.25 29.25C29.25 31.3211 30.9289 33 33 33Z"
			fill="white"
		/>
	</svg>
);

export default SVGAdvice;
