import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				jeffVanImg: file(relativePath: { eq: "example-hero.jpg" }) {
					publicURL
				}

				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
			}
		`
	);

	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://builders.colston.ltd.uk",
		name: "Colston",
		legalName: "Colston Ltd",
		description:
			"Colston can help you find the right solution to your commercial or residential building needs . We can help if you’re looking for an assessment and repair of a ceiling , partition , interior space, exterior fabric or carry out a full refurbishment of a commercial or residential building, provide property maintenance or fit out services.",
		url: "https://www.colston.ltd.uk",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "Eastbourne",
				sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
			},
		],
		email: "office@colston.ltd.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "10",
		location: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		foundingLocation: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		slogan: "Your local trusted builders",
		telephone: "+441323700306",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Eastbourne",
			addressRegion: "East Sussex",
			postalCode: "BN21 1TH",
			streetAddress: "2 Willingdon Road",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "0.26667991545076214",
			longitude: "50.77601832588585",
		},
		hasMap: "https://goo.gl/maps/LDegyXUa7ZEoP4xo9",
		openingHours: "Mo,Tu,We,Th,Fr 08:30-16:30",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "16:30",
				dayOfWeek: "http://schema.org/Monday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "16:30",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "16:30",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "16:30",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "16:30",
				dayOfWeek: "http://schema.org/Friday",
				opens: "08:30",
			},
		],
		founder: {
			"@type": "Person",
			name: "Alex Nicholls",
			id: "https://www.colston.ltd.uk/about-us#alex",
			url: "https://www.colston.ltd.uk/about-us",
			description:
				"Alex Nicholls is a qualified surveyor and an Associate RICS member. He has over 35 years experience in Facilities and Property Management which gives him a keen eye for identifying the causes of a problem and finding the right solution faster than other building firms that may need consult and external engineer or surveyor. Colston is also a licensed waste carrier. Alex is supported in the business by his wife Karen, also a Building Surveyor, to provide expertise to Colstons Clients.",
		},
		// aggregateRating: {
		// 	"@type": "AggregateRating",
		// 	bestRating: "5",
		// 	worstRating: "1",
		// 	ratingValue: "4.9",
		// 	reviewCount: `${reviewCount}`,
		// 	url: "https://www.example.co.uk/reviews",
		// },
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.colston.ltd.uk/contact-us",
			url: "https://www.colston.ltd.uk/contact-us",
			// "image": `${data.schemaLogo.publicURL}`,
			name: "Gaye Nethercot",
			description: "Office Manager",
			telephone: "+441323700306",
			contactType: "Head Office",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Eastbourne",
					sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
				},
			],
		},
		logo: `https://colston.rjmdigital.net/wp-content/uploads/2022/01/ColstonLogoStrapline.png`,
		image: `https://colston.rjmdigital.net/wp-content/uploads/2022/01/Photo-2.jpg`,
		sameAs: [
			"https://facebook.com/pages/category/Property-Management-Company/Colston-Limited-218505481527555/",
			"https://goo.gl/maps/onJfFkqoZmyFtAuV8",
			"https://www.instagram.com/colstonltd/",
			"https://www.linkedin.com/company/colston-limited/",
			"https://www.houzz.com/hznb/professionals/specialty-contractors/colston-limited-pfvwgb-pf~1103417303",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
