import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";

const CtaBanner = ({ headline, btnTxt, btnLink }) => {
	const ctaLink = btnLink || "/contact-us";

	return (
		<section className={`bg-secondary py-5`}>
			<Container>
				<Row className="align-items-lg-center">
					<Col className="mb-4 mb-lg-0 text-center">
						<p className="fs-3 text-white">{headline}</p>

						<Button
							as={Link}
							variant={`outline-white`}
							size="lg"
							className="w-100 w-md-auto  pb-2 cta-btn "
							to={ctaLink}
							id="cta-banner-button"
						>
							{btnTxt}
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CtaBanner;
