import * as React from "react";

const SVGMP1 = (props) => (
	<svg
		width={349}
		height={953}
		viewBox="0 0 349 953"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g opacity={0.1}>
			<circle
				cx={-127.5}
				cy={476.5}
				r={334.459}
				stroke="#999A9C"
				strokeWidth={58.0213}
			/>
			<circle
				cx={-127.5}
				cy={476.5}
				r={447.489}
				stroke="#00A8E4"
				strokeWidth={58.0213}
			/>
		</g>
	</svg>
);

export default SVGMP1;
