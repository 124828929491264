import * as React from "react";

const SVG360 = (props) => (
	<svg
		width={66}
		height={66}
		viewBox="0 0 66 66"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect width={66} height={66} fill="#00A8E4" />
		<path
			d="M33 44.25C39.2132 44.25 44.25 39.2132 44.25 33C44.25 26.7868 39.2132 21.75 33 21.75C26.7868 21.75 21.75 26.7868 21.75 33C21.75 39.2132 26.7868 44.25 33 44.25Z"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M33 44.25C35.7614 44.25 38 39.2132 38 33C38 26.7868 35.7614 21.75 33 21.75C30.2386 21.75 28 26.7868 28 33C28 39.2132 30.2386 44.25 33 44.25Z"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.75 33C21.75 35.7614 26.7868 38 33 38C39.2132 38 44.25 35.7614 44.25 33C44.25 30.2386 39.2132 28 33 28C26.7868 28 21.75 30.2386 21.75 33Z"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default SVG360;
