import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Link from "./link";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { FaArrowRight } from "react-icons/fa";
import { GatsbyImage } from "gatsby-plugin-image";
import Navigation from "./navigation";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { FaLongArrowAltRight } from "react-icons/fa";

const HeroMobile = ({
	title,
	description,
	backgroundImage,
	s1Title,
	s1Text,
	s2Title,
	s2Text,
	s3Title,
	s3Text,
	bgColorS1,
	bgColorS2,
	bgColorS3,
}) => {
	const pluginImage = getImage(backgroundImage);
	const isDarken = true;
	const StyledBackground = styled(BgImage)`
		&::before,
		&::after {
			filter: brightness(
				${({ isDarken }) => {
					return isDarken ? "60%" : "100%";
				}}
			);
		}
	`;
	return (
		<Container className=" position-relative d-lg-none px-0 mx-0" fluid>
			<Navigation />
			<Row className="py-5  mx-0 px-4 mw-100">
				<Col xs={12}>
					<Row>
						<Col>
							<p className="text-primary cabin display-1 fw-bold">{title}</p>
							<hr className="bg-secondary w-50 hr-big " />
							<p>{description}</p>
							<Button
								variant="outline-primary"
								className="w-100 w-md-auto  pb-2 cta-btn "
								as={Link}
								to="/contact-us"
							>
								Get in touch
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="px-0 mx-0">
				<Col className="px-0 mx-0">
					<GatsbyImage
						className="w-100"
						image={backgroundImage}
						alt="Hero Image"
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12}>
					<Row className=" gx-0  justify-content-end">
						<div className="d-flex d-md-none">
							<Col className="h-100" xs={6} md={4}>
								<Link
									className="text-decoration-none cta-link"
									to="/services/maintenance"
								>
									<div
										className={` text-white w-100 h-100 p-4 bg-${bgColorS1}`}
									>
										<h4 className="fw-light">{s1Title}</h4>
										<div className="d-flex align-items-center justify-content-center position-relative">
											<p
												style={{ fontSize: "90%" }}
												className="d-inline-block pb-0  mb-0"
											>
												{s1Text}
											</p>
										</div>
										<Row>
											<Col>
												<div
													className={` d-flex justify-content-center mx-auto align-items-center mt-4 bg-${bgColorS1}`}
													style={{
														width: "50px",
														height: "50px",
														borderRadius: "100%",
														border: "1px solid white",
													}}
												>
													<FaLongArrowAltRight className="fs-3" />
												</div>
											</Col>
										</Row>
									</div>
								</Link>
							</Col>
							<Col className="h-100" xs={6} md={4}>
								<Link
									className="text-decoration-none cta-link"
									to="/services/partitions"
								>
									<div
										className={` text-white w-100 h-100 p-4 bg-${bgColorS2}`}
									>
										<h4 className="fw-light">{s2Title}</h4>
										<div className="d-flex align-items-center justify-content-center position-relative">
											<p
												style={{ fontSize: "90%" }}
												className="d-inline-block pb-0  mb-0"
											>
												{s2Text}
											</p>
										</div>
										<Row>
											<Col>
												<div
													className={` d-flex justify-content-center mx-auto align-items-center mt-4 bg-${bgColorS2}`}
													style={{
														width: "50px",
														height: "50px",
														borderRadius: "100%",
														border: "1px solid white",
													}}
												>
													<FaLongArrowAltRight className="fs-3" />
												</div>
											</Col>
										</Row>
									</div>
								</Link>
							</Col>
						</div>
						<Col className="h-100 d-none d-md-inline-block" xs={6} md={4}>
							<Link
								className="text-decoration-none cta-link"
								to="/services/maintenance"
							>
								<div className={` text-white w-100 h-100 p-4 bg-${bgColorS1}`}>
									<h4 className="fw-light">{s1Title}</h4>
									<div className="d-flex align-items-center justify-content-center position-relative">
										<p
											style={{ fontSize: "90%" }}
											className="d-inline-block pb-0  mb-0"
										>
											{s1Text}
										</p>
									</div>
									<Row>
										<Col>
											<div
												className={` d-flex justify-content-center mx-auto align-items-center mt-4 bg-${bgColorS1}`}
												style={{
													width: "50px",
													height: "50px",
													borderRadius: "100%",
													border: "1px solid white",
												}}
											>
												<FaLongArrowAltRight className="fs-3" />
											</div>
										</Col>
									</Row>
								</div>
							</Link>
						</Col>
						<Col className="h-100 d-none d-md-inline-block" xs={6} md={4}>
							<Link
								className="text-decoration-none cta-link"
								to="/services/partitions"
							>
								<div className={` text-white w-100 h-100 p-4 bg-${bgColorS2}`}>
									<h4 className="fw-light">{s2Title}</h4>
									<div className="d-flex align-items-center justify-content-center position-relative">
										<p
											style={{ fontSize: "90%" }}
											className="d-inline-block pb-0  mb-0"
										>
											{s2Text}
										</p>
									</div>
									<Row>
										<Col>
											<div
												className={` d-flex justify-content-center mx-auto align-items-center mt-4 bg-${bgColorS2}`}
												style={{
													width: "50px",
													height: "50px",
													borderRadius: "100%",
													border: "1px solid white",
												}}
											>
												<FaLongArrowAltRight className="fs-3" />
											</div>
										</Col>
									</Row>
								</div>
							</Link>
						</Col>
						<Col className="h-100" xs={12} md={4}>
							<Link
								className="text-decoration-none cta-link"
								to="/services/suspended-ceilings-in-east-sussex"
							>
								<div className={` text-white w-100  h-100 p-4 bg-${bgColorS3}`}>
									<h4 className="fw-light  ">{s3Title}</h4>
									<div className="d-flex align-items-center justify-content-center   position-relative">
										<p
											style={{ fontSize: "90%" }}
											className="d-inline-block pb-0 mb-0 "
										>
											{s3Text}
										</p>
									</div>
									<Row>
										<Col>
											<div
												className={` d-flex justify-content-center mx-auto align-items-center mt-4 bg-${bgColorS3}`}
												style={{
													width: "50px",
													height: "50px",
													borderRadius: "100%",
													border: "1px solid white",
												}}
											>
												<FaLongArrowAltRight className="fs-3" />
											</div>
										</Col>
									</Row>
								</div>
							</Link>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default HeroMobile;
