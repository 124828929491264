import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Link from "./link";
import Col from "react-bootstrap/Col";

const ServiceCardHome = ({ header, text, icon, fullHeight }) => {
	var trimmedString = text.substr(0, 150);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [textNew, setTextNew] = useState(trimmedString);
	const [expanded, setExpanded] = useState(true);

	return (
		<Col className="mb-7 gx-5 px-4 position-relative " xl={4} md={6}>
			<div
				style={{ zIndex: 2 }}
				className="text-center position-absolute end-0 top-0 translate-middle-y me-4 "
			>
				{icon}
			</div>
			<motion.div
				transition={{ ease: "linear", duration: 0.5 }}
				style={{
					height: "100%",
					minHeight: "153px",
					borderTopLeftRadius: "30px",
					borderBottomRightRadius: "30px",
					zIndex: 1,
					overflow: "hidden",
				}}
				className="   position-relative bg-white px-5 pb-4 pt-5"
			>
				<h3 className=" text-primary fw-light">{header}</h3>
				<hr className="bg-background w-60 hr-big   mb-4" />

				<AnimatePresence>
					{expanded === true && (
						<motion.p
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.5 }}
						>
							{text}
						</motion.p>
					)}
				</AnimatePresence>
			</motion.div>
		</Col>
	);
};

export default ServiceCardHome;
