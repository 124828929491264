import { Link, useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/layout';
import Hero from '../components/hero';
import HeroMobile from '../components/home-hero-mobile';
import ImageSection from '../components/image-section';
import CtaBanner from '../components/cta-banner';
import ServiceCardHome from '../components/service-card-home';
import SVGFastResponse from '../components/svg/fast-response-svg';
import Button from 'react-bootstrap/Button';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import LocalBusinessSchema from '../components/schema/localBusinessSchema';
import { Helmet } from 'react-helmet';
import { GatsbyImage } from 'gatsby-plugin-image';
import SVGMP1 from '../components/svg/mp1-svg';
import SVGHP1 from '../components/svg/hp1-svg';
import SVGHP2 from '../components/svg/hp2-svg';
import SVG360 from '../components/svg/360-svg';
import SVGAdvice from '../components/svg/advice-svg';
import { motion, AnimatePresence } from 'framer-motion';
import HTMLReactParser from 'html-react-parser';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Colston-Hero-New" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      WWDImg: wpMediaItem(title: { eq: "What-We-Do-Image-HP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      WCCImg: wpMediaItem(title: { eq: "Company-History-Image-AUP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      AUCTImg: wpMediaItem(title: { eq: "A-Bit_About-Alex-Image-AUP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      homePage: wpHomePage {
        homePageFields {
          heroSection {
            body
            title
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            bottomTabs {
              tab1 {
                body
                btnText
                title
              }
              tab2 {
                body
                btnText
                title
              }
              tab3 {
                body
                btnText
                title
              }
            }
          }
          adviceSection {
            body
            title
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          whatWeDoAsProfessionalBuilders {
            body
            btnText
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            subHeading
            title
          }
          contactUsSection {
            btnText
            title
          }
          whyChooseColston {
            body
            title
            cards {
              card1 {
                body
                title
                icon {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
              card2 {
                body
                title
                icon {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
              card3 {
                body
                title
                icon {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
  // const WWDImg = data.WWDImg?.localFile.childImageSharp.gatsbyImageData;
  // const WCCImg = data.WCCImg?.localFile.childImageSharp.gatsbyImageData;
  // const AUCTImg = data.AUCTImg?.localFile.childImageSharp.gatsbyImageData;
  const Homepage = data.homePage?.homePageFields;

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Home',
        item: {
          url: `${siteUrl}`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {' '}
        <script type='application/ld+json'>{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <LocalBusinessSchema />
      <GatsbySeo
        title='Builders in Eastbourne, East Sussex | Building contractors'
        description='Looking for trusted local builders that can deliver large-scale projects on budget and on time? We are a building firm in Eastbourne, East Sussex'
        language='en'
        openGraph={{
          type: 'website',
          url: `${siteUrl}`,
          title: 'Builders in Eastbourne, East Sussex | Building contractors',
          description:
            'Looking for trusted local builders that can deliver large-scale projects on budget and on time? We are a building firm in Eastbourne, East Sussex',
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />
      <SVGMP1
        style={{ zIndex: '-1' }}
        className='position-absolute d-none d-xl-block top-60 start-0'
      />
      <SVGMP1
        style={{ zIndex: '-1' }}
        className='position-absolute d-none d-lg-block d-xl-none top-70 start-0'
      />
      <Hero
        title={<span>{HTMLReactParser(Homepage.heroSection.title)}</span>}
        description={HTMLReactParser(Homepage.heroSection.body)}
        backgroundImage={
          Homepage?.heroSection?.image?.node?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        s1Title={Homepage.heroSection.bottomTabs.tab1.title}
        s1Text={Homepage.heroSection.bottomTabs.tab1.body}
        s1BtnText={Homepage.heroSection.bottomTabs.tab1.btnText}
        s2Title={Homepage.heroSection.bottomTabs.tab2.title}
        s2Text={Homepage.heroSection.bottomTabs.tab2.body}
        s2BtnText={Homepage.heroSection.bottomTabs.tab2.btnText}
        s3Title={Homepage.heroSection.bottomTabs.tab3.title}
        s3Text={Homepage.heroSection.bottomTabs.tab2.body}
        s3BtnText={Homepage.heroSection.bottomTabs.tab3.btnText}
        bgColorS1='primary'
        bgColorS2='secondary'
        bgColorS3='background'
      />
      <HeroMobile
        title={<span>{HTMLReactParser(Homepage.heroSection.title)}</span>}
        description={HTMLReactParser(Homepage.heroSection.body)}
        backgroundImage={
          Homepage?.heroSection?.image?.node?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        s1Title={Homepage.heroSection.bottomTabs.tab1.title}
        s1Text={Homepage.heroSection.bottomTabs.tab1.body}
        s1BtnText={Homepage.heroSection.bottomTabs.tab1.btnText}
        s2Title={Homepage.heroSection.bottomTabs.tab2.title}
        s2Text={Homepage.heroSection.bottomTabs.tab2.body}
        s2BtnText={Homepage.heroSection.bottomTabs.tab2.btnText}
        s3Title={Homepage.heroSection.bottomTabs.tab3.title}
        s3Text={Homepage.heroSection.bottomTabs.tab2.body}
        s3BtnText={Homepage.heroSection.bottomTabs.tab3.btnText}
        bgColorS1='primary'
        bgColorS2='secondary'
        bgColorS3='background'
      />
      <SVGHP1
        style={{ zIndex: '-1' }}
        className='position-absolute d-none d-lg-block end-0'
      />
      <section style={{ zIndex: '1' }} className='py-lg-9 py-5'>
        <ImageSection
          title={Homepage.whatWeDoAsProfessionalBuilders.title}
          image={
            Homepage?.whatWeDoAsProfessionalBuilders?.image?.node?.localFile
              ?.childImageSharp?.gatsbyImageData
          }
          imageAlt={
            Homepage?.whatWeDoAsProfessionalBuilders?.image?.node?.altText
          }
          text={HTMLReactParser(Homepage.whatWeDoAsProfessionalBuilders.body)}
          imgSpan={6}
          textSpan={6}
          rounded='left-rounded'
          textPos='top-10 start--20  '
          imgWidth='100%'
          cta={true}
          ctaText={Homepage.whatWeDoAsProfessionalBuilders.subHeading}
          btnText={Homepage.whatWeDoAsProfessionalBuilders.btnText}
          ctaPos='end--10'
        />
      </section>
      <section className='pt-lg-9 my-lg-7 bg-primary'>
        <Container>
          <Row className='pb-8'>
            <Col className='gx-5 py-5 py-lg-0' lg={6}>
              <GatsbyImage
                className='w-100 w-lg-auto'
                image={
                  Homepage?.whyChooseColston?.image?.node?.localFile
                    ?.childImageSharp?.gatsbyImageData
                }
                alt={Homepage?.whyChooseColston?.altText}
              />
            </Col>
            <Col className='text-white d-flex align-items-center gx-5' lg={6}>
              <div className='d-block'>
                <h2 className=' fs-1'>{Homepage.whyChooseColston.title}</h2>
                <hr className='bg-background w-30 hr-big ' />
                {HTMLReactParser(Homepage.whyChooseColston.body)}
              </div>
            </Col>
          </Row>
          <Row>
            <ServiceCardHome
              fullHeight='485px'
              icon={<SVG360 />}
              header={Homepage.whyChooseColston.cards.card1.title}
              text={Homepage.whyChooseColston.cards.card1.body}
            />
            <ServiceCardHome
              fullHeight='591px'
              icon={<SVGFastResponse />}
              header={Homepage.whyChooseColston.cards.card2.title}
              text={Homepage.whyChooseColston.cards.card2.body}
            />
            <ServiceCardHome
              fullHeight='538px'
              icon={<SVGAdvice />}
              header={Homepage.whyChooseColston.cards.card3.title}
              text={Homepage.whyChooseColston.cards.card3.body}
            />
          </Row>
        </Container>
        <CtaBanner
          headline={Homepage.contactUsSection.title}
          btnTxt={Homepage.contactUsSection.btnText}
          btnLink='/contact-us'
        />
      </section>
      <SVGHP2
        style={{ zIndex: '-1' }}
        className='position-absolute d-none d-lg-block start-0'
      />
      <section
        style={{ zIndex: '1' }}
        className='pb-lg-10 mb-lg-5 py-5 pt-lg-0'
      >
        <ImageSection
          title={Homepage.adviceSection.title}
          image={
            Homepage?.adviceSection?.image?.node?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          imageAlt={Homepage?.adviceSection?.image?.node?.altText}
          text={HTMLReactParser(Homepage.adviceSection.body)}
          imgSpan={6}
          imgWidth='100%'
          imgHeight='38rem'
          rounded='left-rounded'
          textSpan={6}
          textPos='top-50 translate-middle-y start--20'
        />
      </section>
    </Layout>
  );
};

export default IndexPage;
