import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from 'react-bootstrap/Button';
import Link from './link';

const ImageSection = ({
  title,
  image,
  imageAlt,
  text,
  order,
  imgSpan,
  textSpan,
  textPos,
  cta,
  ctaText,
  ctaPos,
  imgHeight,
  eHeight,
  imgWidth,
  rounded,
  align,
  btnText,
}) => {
  const emptyheight = eHeight || '177px';
  const imageHeight = imgHeight || 'auto';
  const imageWidth = imgWidth || 'auto';
  const buttonText = btnText || 'Speak to us';
  return (
    <Container>
      <Row className={`pt-4 gx-0 ${align}`}>
        <Col
          style={{ zIndex: 1 }}
          className={`${
            order ? 'text-end' : 'text-end '
          } mb-3 mb-lg-0 d-none d-xl-block position-relative`}
          lg={{ span: imgSpan, order: order }}
        >
          <GatsbyImage
            style={{ height: imageHeight, width: imageWidth }}
            image={image}
            className={`${rounded}  `}
            alt={imageAlt}
          />
        </Col>

        <Col
          className={` d-none d-xl-block ${align ? '' : 'position-relative'}`}
          lg={textSpan}
        >
          <div
            className={`p-5 position-absolute bg-white  mw-100 ${textPos}`}
            style={{
              boxShadow: ' 2px 4px 20px rgba(0, 0, 0, 0.15)',
              zIndex: 2,
            }}
          >
            <h2>{title}</h2>
            <hr className='bg-primary w-40 hr-big ' />
            <p>{text}</p>
            {cta === true && (
              <div
                style={{ height: emptyheight, minHeight: emptyheight }}
                className='    '
              >
                <div
                  style={{
                    background: 'rgba(4, 114, 153, 0.45)',
                    backdropFilter: 'blur(48px)',
                    width: '520px',
                  }}
                  className={`pt-3 pb-4 px-5   position-absolute ${ctaPos}`}
                >
                  <Row>
                    <Col className='text-center'>
                      {' '}
                      <p className='text-white  fs-4'>{ctaText}</p>
                      <Button
                        variant='outline-white'
                        as={Link}
                        to='/contact-us'
                        className='w-100 w-md-auto  pb-2 cta-btn '
                      >
                        {buttonText}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </Col>
        <div
          className='d-xl-none w-lg-80 mx-auto'
          style={{
            boxShadow: ' 2px 4px 20px rgba(0, 0, 0, 0.15)',
            zIndex: 2,
          }}
        >
          <Col
            xs={12}
            style={{ zIndex: 1 }}
            className={` mb-0   position-relative `}
          >
            <GatsbyImage
              className='image-section-image'
              style={{
                borderRadius: '0px',
                width: '100%',
              }}
              image={image}
              alt={imageAlt}
            />
          </Col>
          <Col className='d-xl-none'>
            {' '}
            <div className={`py-5 px-3 bg-white  mw-100 `}>
              <h2>{title}</h2>
              <hr className='bg-primary w-40 hr-big ' />
              <p>{text}</p>
            </div>
            {cta === true && (
              <div
                style={{
                  background: 'rgba(4, 114, 153, 0.45)',
                  backdropFilter: 'blur(48px)',
                }}
                className={`pt-3 pb-4 px-3 px-md-5 w-100  `}
              >
                <Row>
                  <Col className='text-center'>
                    {' '}
                    <p className='text-white  fs-4'>{ctaText}</p>
                    <Button
                      variant='outline-white'
                      as={Link}
                      to='/contact-us'
                      className='w-100 w-md-auto  pb-2 cta-btn '
                    >
                      {buttonText}
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default ImageSection;
