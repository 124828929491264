import * as React from "react";

const SVGFastResponse = (props) => (
	<svg
		width={66}
		height={66}
		viewBox="0 0 66 66"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M0 0H66V66H0V0Z" fill="#00A8E4" />
		<path
			d="M23 39.4625V23H43V30.5H45.5V23C45.5 21.625 44.375 20.5 43 20.5H23C21.625 20.5 20.5125 21.625 20.5125 23L20.5 45.5L25.5 40.5H36.75V38H24.4625L23 39.4625Z"
			fill="white"
		/>
		<path
			d="M46.125 38H43.375L45.5 33H39.25V40.5H41.75V46.75L46.125 38Z"
			fill="white"
		/>
	</svg>
);

export default SVGFastResponse;
